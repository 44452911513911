<template>
  <b-card>
    <!-- Alert for Feedback (Moved to Top) -->
    <b-alert
      v-model="alertVisible"
      :show="alertVisible"
      :variant="alertVariant"
      dismissible
      class="mb-3"
    >
      {{ alertMessage }}
    </b-alert>

    <!-- Search Input -->
    <b-row class="mb-3">
      <b-col lg="4" md="6">
        <b-form-input
          v-model="searchQuery"
          placeholder="Rechercher Fournisseurs..."
          type="search"
          icon="search"
        />
      </b-col>
    </b-row>

    <!-- Loading Spinner -->
    <div
      v-if="isLoading"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Loading"
      />
    </div>

    <!-- Suppliers Table -->
    <b-table
      :items="filteredSuppliers"
      :fields="tableFields"
      responsive
      hover
    >
      <!-- Synchronization Status Column -->
      <template #cell(synchronization_status)="row">
        <b-badge
          :variant="row.item.is_synchronized ? 'success' : 'secondary'"
        >
          {{ row.item.is_synchronized ? 'Synchronisé' : 'Non Synchronisé' }}
        </b-badge>
      </template>

      <!-- Synchronized Companies Column -->
      <template #cell(synchronized_companies)="row">
        <span v-if="row.item.synchronized_companies.length">
          {{ row.item.synchronized_companies.map(c => c.name).join(', ') }}
        </span>
        <span v-else>-</span>
      </template>

      <!-- Actions Column -->
      <template #cell(actions)="row">
        <b-dropdown text="Manage" variant="primary" size="sm">
          <b-dropdown-item
            @click="openSynchronizationModal(row.item)"
          >
            {{ row.item.is_synchronized ? 'Modifier Synchronisation' : 'Synchroniser' }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="row.item.is_synchronized"
            variant="danger"
            @click="desynchronizeSupplier(row.item)"
          >
            Désynchroniser
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Synchronization Modal -->
    <b-modal
      id="synchronization-modal"
      :title="modalTitle"
      @ok="confirmSynchronization"
      ok-variant="primary"
      cancel-variant="secondary"
    >
      <b-form-group label="Société Externe">
        <v-select
          v-model="selectedOutsourcingCompany"
          :options="outsourcingCompanies"
          placeholder="Séléctionner une société"
          label="name"
        />
      </b-form-group>

      <p v-if="selectedSupplier && selectedSupplier.is_synchronized" class="text-warning">
        Cela mettra à jour la synchronisation actuelle avec la société sélectionnée.
      </p>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BTable, BBadge, BDropdown, BDropdownItem,
  BModal, BFormGroup, BAlert, BSpinner, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'SupplierSynchronizationManagement',
  components: {
    vSelect,
    BCard,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormGroup,
    BAlert,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
  },
  data() {
    return {
      suppliers: [],
      outsourcingCompanies: [],
      selectedSupplier: null,
      selectedOutsourcingCompany: null,
      isLoading: false,
      alertVisible: false,
      alertVariant: '',
      alertMessage: '',
      searchQuery: '',
      tableFields: [
        { key: 'username', label: 'Nom Fournisseurs' },
        { key: 'synchronization_status', label: 'Status' },
        { key: 'synchronized_companies', label: 'Société Synchronisées' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    modalTitle() {
      return this.selectedSupplier
        ? `Synchroniser ${this.selectedSupplier.username}`
        : 'Synchronize Supplier'
    },
    filteredSuppliers() {
      if (!this.searchQuery) return this.suppliers

      const query = this.searchQuery.toLowerCase()
      return this.suppliers.filter(supplier => supplier.username.toLowerCase().includes(query))
    },
  },
  created() {
    this.fetchOutsourcingCompanies()
    this.fetchSuppliers()
  },
  methods: {
    async fetchOutsourcingCompanies() {
      try {
        this.isLoading = true
        const { data } = await axios.get('api/companies/outsourcing-company/')
        this.outsourcingCompanies = data
      } catch (error) {
        this.showAlert('danger', 'Failed to load outsourcing companies')
        console.error('Error fetching outsourcing companies:', error)
      } finally {
        this.isLoading = false
      }
    },

    async fetchSuppliers() {
      try {
        this.isLoading = true
        const { data } = await axios.get('api/auth/users/suppliers/outSourcing/')
        this.suppliers = data
      } catch (error) {
        this.showAlert('danger', 'Failed to load suppliers')
        console.error('Error fetching suppliers:', error)
      } finally {
        this.isLoading = false
      }
    },

    openSynchronizationModal(supplier) {
      this.selectedSupplier = supplier
      this.selectedOutsourcingCompany = supplier.is_synchronized
        ? supplier.synchronized_companies[0]
        : null

      this.$bvModal.show('synchronization-modal')
    },

    async confirmSynchronization() {
      if (!this.selectedSupplier || !this.selectedOutsourcingCompany) {
        this.showAlert('warning', 'Please select an outsourcing company')
        return
      }

      try {
        this.isLoading = true
        await axios.post('api/companies/update-supplier-outsourcing/', {
          supplier_id: this.selectedSupplier.id,
          outsourcing_company_id: this.selectedOutsourcingCompany.id,
          is_synchronized: true,
        })

        // Refresh suppliers to get updated synchronization status
        await this.fetchSuppliers()

        this.showAlert('success', `${this.selectedSupplier.username} synchronized successfully`)

        // Close modal
        this.$bvModal.hide('synchronization-modal')
      } catch (error) {
        const errorMessage = error.response?.data?.error
          || 'Failed to synchronize supplier'

        this.showAlert('danger', errorMessage)
        console.error('Synchronization error:', error)
      } finally {
        this.isLoading = false
      }
    },

    async desynchronizeSupplier(supplier) {
      try {
        this.isLoading = true
        await axios.post('api/companies/desynchronize-supplier/', {
          supplier_id: supplier.id,
        })

        // Refresh suppliers to get updated synchronization status
        await this.fetchSuppliers()

        this.showAlert('success', `${supplier.username} desynchronized successfully`)
      } catch (error) {
        const errorMessage = error.response?.data?.error
          || 'Failed to desynchronize supplier'

        this.showAlert('danger', errorMessage)
        console.error('Desynchronization error:', error)
      } finally {
        this.isLoading = false
      }
    },

    showAlert(variant, message) {
      this.alertVisible = true
      this.alertVariant = variant
      this.alertMessage = message
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
